exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-wakix-how-does-wakix-work-index-js": () => import("./../../../src/pages/about-wakix/how-does-wakix-work/index.js" /* webpackChunkName: "component---src-pages-about-wakix-how-does-wakix-work-index-js" */),
  "component---src-pages-about-wakix-index-js": () => import("./../../../src/pages/about-wakix/index.js" /* webpackChunkName: "component---src-pages-about-wakix-index-js" */),
  "component---src-pages-about-wakix-wakix-clinical-trial-results-index-js": () => import("./../../../src/pages/about-wakix/wakix-clinical-trial-results/index.js" /* webpackChunkName: "component---src-pages-about-wakix-wakix-clinical-trial-results-index-js" */),
  "component---src-pages-client-bridge-js": () => import("./../../../src/pages/client-bridge.js" /* webpackChunkName: "component---src-pages-client-bridge-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-discussion-guide-index-js": () => import("./../../../src/pages/discussion-guide/index.js" /* webpackChunkName: "component---src-pages-discussion-guide-index-js" */),
  "component---src-pages-discussion-guide-pdf-template-js": () => import("./../../../src/pages/discussion-guide/pdf-template.js" /* webpackChunkName: "component---src-pages-discussion-guide-pdf-template-js" */),
  "component---src-pages-discussion-guide-personalize-js": () => import("./../../../src/pages/discussion-guide/personalize.js" /* webpackChunkName: "component---src-pages-discussion-guide-personalize-js" */),
  "component---src-pages-ess-js": () => import("./../../../src/pages/ess.js" /* webpackChunkName: "component---src-pages-ess-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-builder-js": () => import("./../../../src/pages/inspiration-builder.js" /* webpackChunkName: "component---src-pages-inspiration-builder-js" */),
  "component---src-pages-living-with-narcolepsy-index-js": () => import("./../../../src/pages/living-with-narcolepsy/index.js" /* webpackChunkName: "component---src-pages-living-with-narcolepsy-index-js" */),
  "component---src-pages-living-with-narcolepsy-tips-for-living-with-narcolepsy-index-js": () => import("./../../../src/pages/living-with-narcolepsy/tips-for-living-with-narcolepsy/index.js" /* webpackChunkName: "component---src-pages-living-with-narcolepsy-tips-for-living-with-narcolepsy-index-js" */),
  "component---src-pages-now-approved-in-pediatric-patients-js": () => import("./../../../src/pages/now-approved-in-pediatric-patients.js" /* webpackChunkName: "component---src-pages-now-approved-in-pediatric-patients-js" */),
  "component---src-pages-patient-starter-kit-js": () => import("./../../../src/pages/patient-starter-kit.js" /* webpackChunkName: "component---src-pages-patient-starter-kit-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-and-support-downloads-index-js": () => import("./../../../src/pages/resources-and-support/downloads/index.js" /* webpackChunkName: "component---src-pages-resources-and-support-downloads-index-js" */),
  "component---src-pages-resources-and-support-find-a-healthcare-provider-index-js": () => import("./../../../src/pages/resources-and-support/find-a-healthcare-provider/index.js" /* webpackChunkName: "component---src-pages-resources-and-support-find-a-healthcare-provider-index-js" */),
  "component---src-pages-resources-and-support-frequently-asked-questions-index-js": () => import("./../../../src/pages/resources-and-support/frequently-asked-questions/index.js" /* webpackChunkName: "component---src-pages-resources-and-support-frequently-asked-questions-index-js" */),
  "component---src-pages-resources-and-support-index-js": () => import("./../../../src/pages/resources-and-support/index.js" /* webpackChunkName: "component---src-pages-resources-and-support-index-js" */),
  "component---src-pages-resources-and-support-support-organizations-index-js": () => import("./../../../src/pages/resources-and-support/support-organizations/index.js" /* webpackChunkName: "component---src-pages-resources-and-support-support-organizations-index-js" */),
  "component---src-pages-share-your-story-js": () => import("./../../../src/pages/share-your-story.js" /* webpackChunkName: "component---src-pages-share-your-story-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-starting-wakix-index-js": () => import("./../../../src/pages/starting-wakix/index.js" /* webpackChunkName: "component---src-pages-starting-wakix-index-js" */),
  "component---src-pages-starting-wakix-what-should-i-expect-index-js": () => import("./../../../src/pages/starting-wakix/what-should-i-expect/index.js" /* webpackChunkName: "component---src-pages-starting-wakix-what-should-i-expect-index-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-talking-treatment-js": () => import("./../../../src/pages/talking-treatment.js" /* webpackChunkName: "component---src-pages-talking-treatment-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-treatment-goals-js": () => import("./../../../src/pages/treatment-goals.js" /* webpackChunkName: "component---src-pages-treatment-goals-js" */),
  "component---src-pages-wakix-for-you-support-program-index-js": () => import("./../../../src/pages/wakix-for-you-support-program/index.js" /* webpackChunkName: "component---src-pages-wakix-for-you-support-program-index-js" */),
  "component---src-pages-wakix-personal-stories-index-js": () => import("./../../../src/pages/wakix-personal-stories/index.js" /* webpackChunkName: "component---src-pages-wakix-personal-stories-index-js" */),
  "component---src-pages-why-wakix-js": () => import("./../../../src/pages/why-wakix.js" /* webpackChunkName: "component---src-pages-why-wakix-js" */)
}

